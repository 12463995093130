import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as State from 'src/state';
import Button from 'src/components/Button';
import Marketplace from './Marketplace';
import Text from 'src/components/Text';
import { Linking, ScrollView, StyleSheet, View } from 'react-native';

interface PropsIface {
  onBack: () => void;
}

const MarketplaceCredentialsScreen: React.FC<PropsIface> = (props) => {
  const selectedMetadata = React.useContext(State.Observe.InstitutionLinks.SelectedMetadataFallback.Get);

  const onPasswordReset = React.useCallback(() => {
    if (selectedMetadata.passwordResetUrl != null) {
      Linking.openURL(selectedMetadata.passwordResetUrl);
    }
  }, [selectedMetadata.passwordResetUrl]);

  const onSupportPress = React.useCallback(() => {
    Linking.openURL('https://tools.oneshop.com/in-app/contact');
  }, []);

  return (
    <>
      <ScrollView scrollEnabled style={styles.content} contentContainerStyle={styles.contentContainerStyle}>
        <Marketplace title={selectedMetadata.title} description={selectedMetadata.domain} />
        <Layout.EdgeGutter style={[Constants.GridStyle.MTUnit, Constants.GridStyle.MB2Unit]}>
          <Text style={[Constants.TextStyle.T16M, Constants.GridStyle.MBUnit]}>{'Alternative link methods'}</Text>
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray, Constants.GridStyle.MB2Unit]}>
            {`Used Google, Facebook, or Apple to log in? You can still continue by creating a new password for your ${selectedMetadata.title} account.`}
          </Text>
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>
            {`You can do this by going through the`}{' '}
            <Text style={[Constants.TextStyle.CBlack, Constants.TextStyle.T12B]}>{'“Reset password”'}</Text>{' '}
            {`steps for ${selectedMetadata.title} using the email address associated with your ${selectedMetadata.title} account. Usually this email address is the one ${selectedMetadata.title} always sends email to in order to contact you.`}
          </Text>
          <View style={Constants.GridStyle.MB4Unit} />
          <View style={Constants.GridStyle.MB2Unit}>
            <Button type='primary' onPress={onPasswordReset}>
              {'Take me to reset password'}
            </Button>
          </View>
          <View style={Constants.GridStyle.MB2Unit}>
            <Button type='secondary' onPress={props.onBack}>
              {'Finished, take me back to link'}
            </Button>
          </View>
          <View>
            <Button type='secondary' onPress={onSupportPress}>
              {'Chat with support for help'}
            </Button>
          </View>
        </Layout.EdgeGutter>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  contentContainerStyle: {
    flex: 1,
    justifyContent: 'center',
  },
  logo: {
    width: Constants.Grid.dp(40),
    height: Constants.Grid.dp(40),
    borderRadius: Constants.Grid.dp(20),
  },
});

const WithData: React.FC<PropsIface> = (props) => (
  <State.Observe.InstitutionLinks.SelectedMetadataFallback.Provider>
    <MarketplaceCredentialsScreen {...props} />
  </State.Observe.InstitutionLinks.SelectedMetadataFallback.Provider>
);

export default WithData;
