import * as React from 'react';

interface ArgsIface {
  seconds: number;
}
export const useTimer = (args: ArgsIface) => {
  const [stopped, setStopped] = React.useState(false);
  const [time, setTime] = React.useState(args.seconds);

  React.useEffect(() => {
    let countdown: NodeJS.Timer | null = null;

    if (!stopped) {
      countdown = setInterval(() => {
        setTime((_) => _ - 1);
      }, 1000);
    }

    return () => {
      if (countdown) clearInterval(countdown);
    };
  }, [stopped]);

  React.useEffect(() => {
    if (time === 0) {
      setStopped(true);
    }
  }, [time]);

  return time;
};
