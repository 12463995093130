import * as React from 'react';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as Layout from 'src/components/Layout';
import * as Network from 'src/clients/Network';
import TextInput from 'src/components/TextInput';
import Button from 'src/components/Button';
import { Linking, StyleSheet, TouchableOpacity, View, Text, Image, ActivityIndicator } from 'react-native';

interface PropsIface {
  onBack: () => void;
  onInitiateLink: () => void;
  onSsoPress: () => void;
}

const LinkDirectModal: React.FC<PropsIface> = (props) => {
  const backImageSource = React.useMemo(() => {
    return {
      uri: '/static/images/app/navigation-back.png',
    };
  }, []);

  const lockIconSource = React.useMemo(() => {
    return {
      uri: '/static/images/app/Link/Lock@3x.png',
    };
  }, []);

  const selectedMetadata = React.useContext(State.Observe.InstitutionLinks.SelectedMetadataFallback.Get);
  const requests = Util.Observe.React.useValue(State.Observe.InstitutionLinks.InstitutionLinkRequestsValue);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [username, setUsername] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  /* Ref to not cause the effect to run on change of this value */
  const canRedirectRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (canRedirectRef.current) {
      canRedirectRef.current = false;
      props.onInitiateLink();
    }
  }, [requests]);

  const onPress = React.useCallback(async () => {
    setLoading(true);
    setErrorMessage(null);
    try {
      if (username.trim().length === 0) {
        setErrorMessage('Username can’t be missing!');
        setLoading(false);
        return;
      } else if (password.trim().length === 0) {
        setErrorMessage('Password can’t be missing!');
        setLoading(false);
        return;
      }

      const res = await Network.gql.startInstitutionLink({
        institution: selectedMetadata.institution,
        username,
        password,
      });
      const requestId = res.invsysStartInstitutionLink.requestId;

      if (requestId != null) {
        canRedirectRef.current = true;
        await Network.gql.getAppCriticalMetadata({});
      } else {
        // TODO (albert): Show error
        setLoading(false);
      }
    } catch (e) {
      // TODO (albert): Show error
      setErrorMessage('Something unexpected happen, please try again later.');
      setLoading(false);
    }
  }, [selectedMetadata.institution, username, password]);

  const onPasswordReset = React.useCallback(() => {
    if (selectedMetadata.passwordResetUrl != null) {
      Linking.openURL(selectedMetadata.passwordResetUrl);
    }
  }, [selectedMetadata.passwordResetUrl]);

  return (
    <>
      <View>
        <Layout.EdgeGutter
          style={[
            Constants.GridStyle.FLDR,
            Constants.GridStyle.FLAIC,
            Constants.GridStyle.FLJCSB,
            Constants.GridStyle.MT2Unit,
            Constants.GridStyle.MB2Unit,
          ]}
        >
          <TouchableOpacity onPress={props.onBack}>
            <Image
              source={backImageSource}
              style={[Constants.ImageStyle.StdDim, imageStyles.backIcon]}
              resizeMode='contain'
            />
          </TouchableOpacity>
        </Layout.EdgeGutter>
        <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
          <Text style={[Constants.TextStyle.T16M]}>{selectedMetadata.title}</Text>
          <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{selectedMetadata.domain}</Text>
        </Layout.EdgeGutter>
        <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
          <TextInput
            autoFocus
            autoCapitalize='none'
            autoComplete='off'
            autoCorrect={false}
            placeholder={selectedMetadata.usernameInputLabel ?? 'Username or Email'}
            style={Constants.TextStyle.T12R}
            onChangeText={setUsername}
          />
          <View style={Constants.GridStyle.MBUnit} />
          <View>
            <TextInput
              autoCapitalize='none'
              autoComplete='off'
              autoCorrect={false}
              placeholder='Password'
              textContentType='password'
              style={Constants.TextStyle.T12R}
              onChangeText={setPassword}
            />
            <Image source={lockIconSource} style={styles.lockIcon} />
          </View>
          {password.includes(' ') ? (
            <Text
              style={[
                Constants.GridStyle.MT2Unit,
                Constants.TextStyle.ACenter,
                Constants.TextStyle.CAccentOrange,
                Constants.TextStyle.T10R,
              ]}
            >
              {`Your password contains at least one space.`}
            </Text>
          ) : null}
          {errorMessage != null ? (
            <Text
              style={[
                Constants.GridStyle.MT2Unit,
                Constants.TextStyle.ACenter,
                Constants.TextStyle.CAccentRed,
                Constants.TextStyle.T10R,
              ]}
            >
              {errorMessage}
            </Text>
          ) : null}
          <View style={Constants.GridStyle.MB2Unit} />
          <View style={styles.linkActionsRow}>
            <Button type='primary' disabled={false} onPress={onPress}>
              {loading ? <ActivityIndicator /> : `Start link`}
            </Button>
            {selectedMetadata.passwordResetUrl != null ? (
              <TouchableOpacity style={Constants.GridStyle.ML2Unit} onPress={onPasswordReset}>
                <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray, Constants.TextStyle.ACenter]}>
                  {`Reset password`}
                </Text>
              </TouchableOpacity>
            ) : null}
          </View>
          <TouchableOpacity
            style={[Constants.GridStyle.PL2Unit, Constants.GridStyle.PR2Unit]}
            onPress={props.onSsoPress}
          >
            <Text
              style={[
                Constants.TextStyle.T16R,
                Constants.TextStyle.CDarkGray,
                Constants.TextStyle.ACenter,
                Constants.GridStyle.MT4Unit,
                Constants.GridStyle.MB2Unit,
              ]}
            >
              {`Do you normally log in to ${selectedMetadata.title} with`}{' '}
              <Text style={[Constants.TextStyle.T16R, Constants.TextStyle.CBlack]}>{'Google'}</Text>
              {', '}
              <Text style={[Constants.TextStyle.T16R, Constants.TextStyle.CBlack]}>{'Facebook'}</Text>
              {', or '}
              <Text style={[Constants.TextStyle.T16R, Constants.TextStyle.CBlack]}>{'Apple'}</Text>
              {`? Tap here instead.`}
            </Text>
          </TouchableOpacity>
        </Layout.EdgeGutter>
      </View>
    </>
  );
};

const WithData: React.FC<PropsIface> = (props) => {
  return (
    <State.Observe.InstitutionLinks.SelectedMetadataFallback.Provider>
      <LinkDirectModal {...props} />
    </State.Observe.InstitutionLinks.SelectedMetadataFallback.Provider>
  );
};

export default WithData;

const styles = StyleSheet.create({
  rightColumn: {
    flex: 1,
  },
  notAvailableYet: {
    opacity: 0.4,
  },
  lockIcon: {
    position: 'absolute',
    top: Constants.Grid.dp(14),
    right: Constants.Grid.dp(12),
    width: Constants.Grid.dpFromPx(18),
    height: Constants.Grid.dpFromPx(18),
    tintColor: Constants.NewColor.DarkGray,
  },
  linkActionsRow: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const imageStyles = StyleSheet.create({
  backIcon: {
    tintColor: Constants.NewColor.Black,
  },
});
