import * as React from 'react';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Layout from 'src/components/Layout';
import * as Util from 'src/util';
import * as SecureStore from 'src/clients/SecureStore';
import SitePickerRow from './SitePickerRow';
import { StyleSheet, TouchableOpacity, View, Text, Image } from 'react-native';

interface PropsIface {
  onBack: () => void;
  onWebLinkPress: () => void;
  onLinkDirectPress: () => void;
}

const backIconSource = {
  uri: '/static/images/app/navigation-back.png',
};

const SitePickerModal: React.FC<PropsIface> = (props) => {
  const authMetadata = Util.Observe.React.useValue(State.Observe.InstitutionLinks.MetadataValue);
  const featureSwitches = Util.Observe.React.useValue(State.Observe.StaticFeatureSwitches.Value);
  const secureValues = SecureStore.get([SecureStore.Key.BusinessManagerId, SecureStore.Key.BusinessId]);
  const grailedLinkingBetaTester = (featureSwitches['2022-12-28-grailed-linking-business-ids'] ?? []).includes(
    secureValues['x-sp-b-id'] ?? ''
  );
  const allowGrailedLinking = Boolean(featureSwitches['2022-12-28-grailed-linking']) || grailedLinkingBetaTester;

  return (
    <>
      <View>
        <Layout.EdgeGutter
          style={[
            Constants.GridStyle.FLDR,
            Constants.GridStyle.FLAIC,
            Constants.GridStyle.FLJCSB,
            Constants.GridStyle.MT2Unit,
            Constants.GridStyle.MB2Unit,
          ]}
        >
          <TouchableOpacity onPress={props.onBack}>
            <Image
              source={backIconSource}
              style={[Constants.ImageStyle.StdDim, styles.backIcon]}
              resizeMode='contain'
            />
          </TouchableOpacity>
        </Layout.EdgeGutter>
        <Layout.EdgeGutter>
          <Text style={[Constants.TextStyle.T24B, Constants.GridStyle.MB2Unit]}>{'Pick a site'}</Text>
          {authMetadata.map((institutionMetadata) => {
            const grailedAvailable =
              institutionMetadata.institution === State.Types.InstitutionEnum.Grailed && allowGrailedLinking;
            const showInstitution = institutionMetadata.availability === 1 || grailedAvailable;

            return showInstitution ? (
              <SitePickerRow
                key={institutionMetadata.domain}
                institution={institutionMetadata.institution}
                logoUrl={institutionMetadata.domain}
                title={institutionMetadata.title}
                authMethodItems={institutionMetadata.authMethodItems}
                onWebLinkPress={props.onWebLinkPress}
                onLinkDirectPress={props.onLinkDirectPress}
              />
            ) : null;
          })}
        </Layout.EdgeGutter>
      </View>
    </>
  );
};

export default SitePickerModal;

const styles = StyleSheet.create({
  backIcon: {
    tintColor: Constants.NewColor.Black,
  },
});
