import * as Constants from 'src/constants';
import * as React from 'react';
import { StyleSheet, TextInput as NativeTextInput, TextInputProps } from 'react-native';

const TextInput: React.FC<React.PropsWithChildren<TextInputProps>> = (props) => (
  <NativeTextInput
    placeholderTextColor={Constants.NewColor.DarkGray}
    {...props}
    style={[
      /**
       * This color default is to support dark mode
       */
      Constants.TextStyle.CMidnight,
      Constants.TextStyle.T16R,
      props.multiline ? styles.multilineRoot : styles.root,
      props.style,
    ]}
  />
);

export const styles = StyleSheet.create({
  root: {
    paddingHorizontal: Constants.Grid.dp(12),
    height: Constants.Grid.dpFromPx(42),
    borderRadius: 3 * Constants.Grid.Unit,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: Constants.BrandColor.DarkGray,
  },
  multilineRoot: {
    paddingHorizontal: Constants.Grid.dp(12),
    paddingTop: Constants.Grid.dp(12),
    paddingBottom: Constants.Grid.dp(12),
    borderRadius: 3 * Constants.Grid.Unit,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: Constants.BrandColor.DarkGray,
  },
});

export default TextInput;
