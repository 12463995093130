import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as State from 'src/state';
import * as Format from 'src/components/Format';
import AlertModal from 'src/components/AlertModal';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import TextInput from 'src/components/TextInput';
import { useTimer } from './useTimer';
import * as Network from 'src/clients/Network';
import * as Util from 'src/util';

interface PropsIface {
  onLinkChecking: () => void;
  onClose: () => void;
  onFailure?: () => void;
}

const Inner: React.FC<PropsIface> = (props) => {
  const requests = Util.Observe.React.useValue(State.Observe.InstitutionLinks.InstitutionLinkRequestsValue);
  const selectedMetadata = React.useContext(State.Observe.InstitutionLinks.SelectedMetadataFallback.Get);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>('');
  const [alertTitle, setAlertTitle] = React.useState<string>('');
  const [alertDetails, setAlertDetails] = React.useState<string>('');
  const [alertVisible, setAlertVisible] = React.useState<boolean>(false);

  const canRedirectRef = React.useRef<boolean>(false);
  const seconds = useTimer({ seconds: 30 });

  const requestMaybe = React.useMemo(() => {
    const reqSorted = [...requests].sort((a, b) => b.startAt - a.startAt);
    return reqSorted.find((_) => _.institution === selectedMetadata.institution);
  }, [requests, selectedMetadata]);

  const channel = requestMaybe?.channel;

  React.useEffect(() => {
    if (canRedirectRef.current) {
      canRedirectRef.current = false;
      props.onLinkChecking();
    }
  }, [requests]);

  const onAcceptAlert = React.useCallback(() => {
    setAlertVisible(false);
    props.onClose();
  }, []);

  const onTokenChannelActionPress = React.useCallback(async () => {
    setLoading(true);
    /* This SHOULD exist */
    const selectedRequest = requests
      .filter((_) => _.institution === selectedMetadata.institution)
      .find((_) => _.status === 'PENDING_MFA_ANSWER');

    if (selectedRequest == null) {
      setAlertTitle('Failed to link.');
      setAlertDetails("We couldn't link your account for some reason. Try again later.");
      setAlertVisible(true);
    } else {
      try {
        await Network.gql.putMfaToken({
          mfaToken: value,
          requestId: selectedRequest?.id,
        });
        canRedirectRef.current = true;
        await Network.gql.getAppCriticalMetadata({});
      } catch {
        try {
          /* Dumb retry */
          await Network.gql.putMfaToken({
            mfaToken: value,
            requestId: selectedRequest?.id,
          });
          canRedirectRef.current = true;
          await Network.gql.getAppCriticalMetadata({});
        } catch {
          setAlertTitle('Failed to link');
          setAlertDetails('Something happened on our end. Try again later.');
          setAlertVisible(true);
          props.onClose();
        }
      } finally {
        setLoading(false);
      }
    }
  }, [requests, selectedMetadata, value]);

  React.useEffect(() => {
    if (seconds === 0) {
      setAlertTitle('The request expired.');
      setAlertDetails('Please try linking again to get a new code.');
      setAlertVisible(true);
    }
  }, [seconds]);

  const instructionNode =
    channel === State.Types.MfaChannel.Sms ? (
      <Text style={[Constants.TextStyle.T12M]}>{`Please check for a code on the phone \nassociated with your ${
        selectedMetadata.title != null ? selectedMetadata.title : 'marketplace'
      } account`}</Text>
    ) : channel === State.Types.MfaChannel.Email ? (
      <Text style={[Constants.TextStyle.T12M]}>{`Please check for a code in the email \nassociated with your ${
        selectedMetadata.title != null ? selectedMetadata.title : 'marketplace'
      } account`}</Text>
    ) : null;

  const alertDetailsProp = alertDetails === '' ? undefined : alertDetails;

  return (
    <>
      <View style={styles.root}>
        <Layout.EdgeGutter style={[styles.content, Constants.GridStyle.FLF1, Constants.GridStyle.FLJCC]}>
          <View style={styles.text}>
            <Text style={[Constants.TextStyle.T24B]}>{'One more thing...'}</Text>
          </View>
          <View style={styles.text}>{instructionNode}</View>
          <View style={[styles.input, Constants.GridStyle.FLJCC]}>
            <TextInput
              autoFocus
              autoCapitalize='none'
              autoComplete='off'
              autoCorrect={false}
              placeholder='Enter Code'
              style={Constants.TextStyle.T12R}
              textContentType='oneTimeCode'
              onChangeText={setValue}
            />
          </View>
          <View style={[Constants.GridStyle.FLJCC, Constants.GridStyle.FLAIC, Constants.GridStyle.FLDR]}>
            <View style={Constants.GridStyle.MR2Unit}>
              <Text style={Constants.TextStyle.T12M}>
                {'Expires in '}
                <Format.WithMemo formatter={Util.Format.IntWithCommas} value={seconds} />
                {' seconds'}
              </Text>
            </View>
            <Button type='primary' disabled={loading} onPress={onTokenChannelActionPress}>
              {loading ? <ActivityIndicator /> : `Submit`}
            </Button>
          </View>
        </Layout.EdgeGutter>
      </View>
      <AlertModal visible={alertVisible} onAccept={onAcceptAlert} title={alertTitle} details={alertDetailsProp} />
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    minHeight: Constants.Grid.dp(300),
  },
  content: {
    marginBottom: Constants.Grid.dp(20),
  },
  text: {
    marginBottom: Constants.Grid.dp(30),
  },
  input: {
    marginBottom: Constants.Grid.dp(16),
  },
});

const CheckingInAppMfaScreen: React.FC<PropsIface> = (props) => {
  return (
    <State.Observe.InstitutionLinks.SelectedMetadataFallback.Provider>
      <Inner {...props} />
    </State.Observe.InstitutionLinks.SelectedMetadataFallback.Provider>
  );
};
export default CheckingInAppMfaScreen;
