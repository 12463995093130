import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as State from 'src/state';
import * as Util from 'src/util';
import * as AmplitudeClient from 'src/clients/Amplitude';
import AlertModal from 'src/components/AlertModal';
import Text from 'src/components/Text';
import { ActivityIndicator, Alert, Image, StyleSheet, View } from 'react-native';

interface PropsIface {
  onClose: () => void;
  onTokenMfaAnswer: () => void;
  onInAppMfaAnswer: () => void;
  onSuccess?: () => void;
  onFailure?: () => void;
}

const Inner: React.FC<PropsIface> = (props) => {
  const requests = Util.Observe.React.useValue(State.Observe.InstitutionLinks.InstitutionLinkRequestsValue);
  const selectedMetadata = React.useContext(State.Observe.InstitutionLinks.SelectedMetadataFallback.Get);
  const [alertTitle, setAlertTitle] = React.useState<string>('');
  const [alertDetails, setAlertDetails] = React.useState<string>('');
  const [alertVisible, setAlertVisible] = React.useState<boolean>(false);
  const [isLinkSuccessful, setIsLinkSuccessful] = React.useState<null | boolean>(null);

  const onAlertAccept = React.useCallback(() => {
    setAlertVisible(false);
    if (isLinkSuccessful != null) {
      isLinkSuccessful ? props.onSuccess?.() : props.onFailure?.();
    }
    props.onClose();
  }, [isLinkSuccessful]);

  React.useEffect(() => {
    const reqSorted = [...requests].sort((a, b) => b.startAt - a.startAt);
    const requestMaybe = reqSorted.find((_) => _.institution === selectedMetadata.institution);

    if (requestMaybe?.status != null && !requestMaybe.status.startsWith('PENDING')) {
      AmplitudeClient.logEventAsync(`link/marketplace-credentials-checking/link-finished/any`);
      AmplitudeClient.logEventAsync(
        `link/marketplace-credentials-checking/link-finished/institution/${selectedMetadata.institution}`
      );
      AmplitudeClient.logEventAsync(
        `link/marketplace-credentials-checking/link-finished/status/${requestMaybe.status}`
      );
      AmplitudeClient.logEventAsync(
        `link/marketplace-credentials-checking/link-finished/institution-status/${selectedMetadata.institution}/${requestMaybe.status}`
      );
    }

    /* Either gone or moved to a terminal state */
    if (requestMaybe?.status === 'FAILED_ANOTHER_OWNER') {
      setAlertTitle('Failed to link');
      setAlertDetails('Looks like someone already owns this account');
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    } else if (requestMaybe?.status === 'FAILED_WRONG_MFA_TOKEN') {
      setAlertTitle('Failed to link');
      setAlertDetails('Looks like the code you entered was incorrect');
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    } else if (requestMaybe?.status === 'FAILED_WRONG_PASSWORD') {
      setAlertTitle('Failed to link');
      setAlertDetails('Looks like the password you entered was incorrect');
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    } else if (requestMaybe?.status === 'FAILED_NEEDS_PASSWORD_RESET') {
      setAlertTitle('Failed to link');
      setAlertDetails('Looks like you need to reset your password');
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    } else if (requestMaybe?.status === 'FAILED_NEEDS_SECURITY_QUESTIONS') {
      setAlertTitle('Failed to link');
      setAlertDetails('Looks like you need to set up security questions on your account');
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    } else if (requestMaybe?.status === 'FAILED_MFA_REQUIRED') {
      setAlertTitle('Failed to link');
      setAlertDetails("We can't link accounts with Multi-factor Authentication enabled. Please disable to continue");
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    } else if (requestMaybe?.status === 'FAILED_MFA_TOKEN_TIMEOUT') {
      setAlertTitle('Failed to link');
      setAlertDetails('Looks like your multi-factor authentication attempt expired');
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    } else if (requestMaybe?.status === 'FAILED_MFA_CHANNEL_TIMEOUT') {
      setAlertTitle('Failed to link');
      setAlertDetails('Looks like it took too long to select a multi-factor authentication method');
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    } else if (requestMaybe?.status === 'FAILED_MFA_UNVERIFIED_PHONE_NUMBER') {
      setAlertTitle('Failed to link');
      setAlertDetails("Looks like you don't have a verified phone number on the platform");
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    } else if (requestMaybe?.status === 'FAILED_MFA_DENIED') {
      setAlertTitle('Failed to link');
      setAlertDetails('Looks like the multi-factor authentication attempt was denied');
      setAlertVisible(true);
    } else if (requestMaybe?.status === 'FAILED_ACCOUNT_SUSPENDED') {
      setAlertTitle('Failed to link');
      setAlertDetails('Looks like your account was suspended by the platform');
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    } else if (requestMaybe?.status === 'FAILED_ACCOUNT_LOCKED') {
      setAlertTitle('Failed to link');
      setAlertDetails('The platform has locked your account for too many failed login attempts');
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    } else if (requestMaybe?.status === 'FAILED_WRONG_USERNAME') {
      setAlertTitle('Failed to link');
      setAlertDetails('Looks like the username you entered was incorrect');
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    } else if (requestMaybe?.status === 'FAILED_WRONG_CREDENTIALS') {
      setAlertTitle('Failed to link');
      setAlertDetails('Looks like the credentials you entered were incorrect');
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    } else if (requestMaybe?.status === 'FAILED_MARKETPLACE_AUTH_UNAVAILABLE') {
      setAlertTitle('Failed to link');
      setAlertDetails('The platform is currently experiencing login issues. Try again later.');
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    } else if (requestMaybe?.status === 'FAILED_INVALID_AUTH_METHOD') {
      setAlertTitle('Failed to link');
      setAlertDetails(
        'Your account on the platform has no password, so a link to set one has been emailed to you. Please set a password to continue.'
      );
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    } else if (requestMaybe?.status === 'FAILED_GENERIC') {
      setAlertTitle('Failed to link');
      setAlertDetails('Something happened on our end. Try again later.');
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    } else if (requestMaybe?.status === 'SUCCESS') {
      setAlertTitle('Link successful');
      setAlertDetails('');
      setAlertVisible(true);
      setIsLinkSuccessful(true);
    } else if (requestMaybe?.status === 'PENDING_MFA_ANSWER') {
      if (
        requestMaybe?.channel === State.Types.MfaChannel.Email ||
        requestMaybe?.channel === State.Types.MfaChannel.Sms
      ) {
        props.onTokenMfaAnswer();
      } else if (requestMaybe?.channel === State.Types.MfaChannel.InApp) {
        props.onInAppMfaAnswer();
      }
    } else if (!requestMaybe?.status?.startsWith('PENDING')) {
      setAlertTitle('Failed to link');
      setAlertDetails('Something happened on our end. Try again later.');
      setAlertVisible(true);
      setIsLinkSuccessful(false);
    }
  }, [requests, selectedMetadata]);

  const alertDetailsProp = alertDetails === '' ? undefined : alertDetails;

  return (
    <>
      <View style={[Constants.GridStyle.FLF1, styles.root]}>
        <Layout.EdgeGutter style={[Constants.GridStyle.FLF1, Constants.GridStyle.FLJCC, Constants.GridStyle.FLAIC]}>
          <Text style={[Constants.TextStyle.T24M, styles.checking]}>{'Checking'}</Text>
          <ActivityIndicator />
        </Layout.EdgeGutter>
      </View>
      <AlertModal visible={alertVisible} title={alertTitle} details={alertDetailsProp} onAccept={onAlertAccept} />
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    minHeight: Constants.Grid.dp(300),
  },
  checking: {
    paddingBottom: Constants.Grid.dp(20),
  },
});

const CheckingScreen: React.FC<PropsIface> = (props) => {
  return (
    <State.Observe.InstitutionLinks.SelectedMetadataFallback.Provider>
      <Inner {...props} />
    </State.Observe.InstitutionLinks.SelectedMetadataFallback.Provider>
  );
};
export default CheckingScreen;
