import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import * as State from 'src/state';
import Text from 'src/components/Text';
import { Image, Linking, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';

interface PropsIface {
  onBack: () => void;
}

const backIconSource = {
  uri: '/static/images/app/navigation-back.png',
};

const MarketplaceChromeExtensionScreen: React.FC<PropsIface> = (props) => {
  const selectedMetadata = React.useContext(State.Observe.InstitutionLinks.SelectedMetadataFallback.Get);

  const downloadUrl: string | undefined = React.useMemo(() => {
    return (selectedMetadata.authMethodItems ?? []).find((_) => _.routeParams != null && 'downloadUrl' in _.routeParams)
      ?.routeParams?.downloadUrl;
  }, [selectedMetadata.authMethodItems]);

  const onLinkPress = React.useCallback(() => {
    if (downloadUrl == null) {
      return;
    }
    Linking.openURL(downloadUrl);
  }, [downloadUrl]);

  const downloadLabel: string | undefined = React.useMemo(() => {
    return downloadUrl?.replace(/https?:\/\//g, '');
  }, [downloadUrl]);

  if (downloadLabel == null) {
    return null;
  }

  const header = (
    <Layout.EdgeGutter
      style={[
        Constants.GridStyle.FLDR,
        Constants.GridStyle.FLAIC,
        Constants.GridStyle.FLJCSB,
        Constants.GridStyle.MT2Unit,
        Constants.GridStyle.MB2Unit,
      ]}
    >
      <TouchableOpacity onPress={props.onBack}>
        <Image source={backIconSource} style={[Constants.ImageStyle.StdDim, styles.backIcon]} resizeMode='contain' />
      </TouchableOpacity>
    </Layout.EdgeGutter>
  );

  return (
    <View>
      {header}
      <Layout.EdgeGutter style={Constants.GridStyle.MB2Unit}>
        <Text
          style={[Constants.TextStyle.T24B, Constants.GridStyle.MB2Unit]}
        >{`Link ${selectedMetadata.title} with the OneShop Chrome Extension`}</Text>
        <Text style={[Constants.TextStyle.T16R, Constants.TextStyle.CDarkGray, Constants.GridStyle.MBUnit]}>
          {'Visit the link below on your computer’s Chrome browser to get started.'}
        </Text>
        <Text style={[Constants.TextStyle.T16R, Constants.TextStyle.CDarkGray, Constants.GridStyle.MB4Unit]}>
          {'You only need the Chrome Extension to link and will still be able to manage your account from your phone.'}
        </Text>
        <TouchableOpacity onPress={onLinkPress}>
          <Text style={[Constants.TextStyle.T24M, Constants.TextStyle.CBolt]}>{downloadLabel}</Text>
        </TouchableOpacity>
      </Layout.EdgeGutter>
    </View>
  );
};

const styles = StyleSheet.create({
  backIcon: {
    tintColor: Constants.NewColor.Black,
  },
});

const WithData: React.FC<PropsIface> = (props) => {
  return (
    <State.Observe.InstitutionLinks.SelectedMetadataFallback.Provider>
      <MarketplaceChromeExtensionScreen {...props} />
    </State.Observe.InstitutionLinks.SelectedMetadataFallback.Provider>
  );
};

export default WithData;
