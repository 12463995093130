import * as React from 'react';
import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import Text from 'src/components/Text';
import { StyleSheet, View, Image } from 'react-native';

interface PropsIface {
  title: string;
  description: string;
}

const Marketplace: React.FC<PropsIface> = (props) => {
  return (
    <Layout.EdgeGutter style={[styles.root]}>
      <Text style={[Constants.TextStyle.T16M]}>{props.title}</Text>
      <Text style={[Constants.TextStyle.T12M, Constants.TextStyle.CDarkGray]}>{props.description}</Text>
    </Layout.EdgeGutter>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingVertical: Constants.Grid.dp(24),
  },
});

export default Marketplace;
