import * as React from 'react';
import * as Constants from 'src/constants';
import * as State from 'src/state';
import * as Util from 'src/util';
import Button from 'src/components/Button';
import { TouchableOpacity, View, StyleSheet, Text, Image } from 'react-native';

interface PropsIface {
  title: string;
  logoUrl: string;
  institution: State.Types.InstitutionEnum;
  authMethodItems?: {
    title: string;
    routeName: string;
    routeParams?: {
      downloadUrl?: string;
    };
  }[];
  onWebLinkPress: () => void;
  onLinkDirectPress: () => void;
}

const rightChevronIconSource = {
  uri: '/static/images/app/RightChevronIcon.png',
};

const SitePickerRow: React.FC<PropsIface> = (props) => {
  const selectedInstitution = Util.Observe.React.useValue(State.Observe.InstitutionLinks.SelectedInstitutionValue);
  const isSelected = props.institution === selectedInstitution;
  const authMethodsLength = props?.authMethodItems?.length ?? 0;
  const isMultipleMethods = authMethodsLength >= 2;

  const onPress = React.useCallback(() => {
    if (isSelected && isMultipleMethods) {
      State.Observe.InstitutionLinks.SelectedInstitutionValue.set(null);
      return;
    }

    State.Observe.InstitutionLinks.SelectedInstitutionValue.set(props.institution);
    if (authMethodsLength === 0) {
      props.onLinkDirectPress();
    } else if (authMethodsLength === 1) {
      const routeParams = props.authMethodItems?.[0]?.routeParams;
      if (routeParams != null && 'downloadUrl' in routeParams) {
        props.onWebLinkPress();
      } else {
        props.onLinkDirectPress();
      }
    }
  }, [isSelected, isMultipleMethods, props.authMethodItems, props.onLinkDirectPress, props.onWebLinkPress]);

  const authMethodButtons =
    authMethodsLength >= 2 ? (
      <>
        <Button type='secondary' textStyle={Constants.TextStyle.T12M} onPress={props.onWebLinkPress}>
          {'Web Extension →'}
        </Button>
        <Button
          type='primary'
          style={Constants.GridStyle.MLUnit}
          textStyle={Constants.TextStyle.T12M}
          onPress={props.onLinkDirectPress}
        >
          {'Link Direct →'}
        </Button>
      </>
    ) : null;

  return (
    <>
      <TouchableOpacity style={[Constants.GridStyle.PB2Unit, Constants.GridStyle.FLDR]} onPress={onPress}>
        <View style={Constants.GridStyle.FLDR}>
          <View>
            <Text style={[Constants.TextStyle.T16M]}>{props.title}</Text>
            <Text style={[Constants.TextStyle.T12R, Constants.TextStyle.CDarkGray]}>{props.logoUrl}</Text>
          </View>
        </View>
        <View style={[styles.rightColumn, Constants.GridStyle.FLAIFE, Constants.GridStyle.FLJCC]}>
          {isSelected && isMultipleMethods ? (
            <View style={[Constants.GridStyle.FLDR, Constants.GridStyle.MBLine]}>{authMethodButtons}</View>
          ) : (
            <Image source={rightChevronIconSource} style={Constants.ImageStyle.StdDim} />
          )}
        </View>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  rightColumn: {
    flex: 1,
  },
  notAvailableYet: {
    opacity: 0.4,
  },
});

export default SitePickerRow;
