import * as Constants from 'src/constants';
import * as Layout from 'src/components/Layout';
import React from 'react';
import Button from 'src/components/Button';
import { View, ViewStyle, StyleProp, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';

interface LinkDisclaimerModalPropsIface {
  onClose: () => void;
  onNext: () => void;
  style?: StyleProp<ViewStyle>;
}

const LinkDisclaimerModal: React.FC<LinkDisclaimerModalPropsIface> = (props) => {
  const safetyIconSource = React.useMemo(() => {
    return {
      uri: '/static/images/app/Safety@3x.png',
    };
  }, []);

  const closeIconSource = React.useMemo(() => {
    return {
      uri: '/static/images/app/Clickable/Close.png',
    };
  }, []);

  return (
    <>
      <View style={[Constants.GridStyle.FLF1, Constants.GridStyle.MB4Unit]}>
        <Layout.EdgeGutter
          style={[
            Constants.GridStyle.FLDR,
            Constants.GridStyle.FLAIC,
            Constants.GridStyle.FLJCSB,
            Constants.GridStyle.MT2Unit,
            Constants.GridStyle.MB2Unit,
          ]}
        >
          <TouchableOpacity onPress={props.onClose}>
            <Image
              source={closeIconSource}
              style={[Constants.ImageStyle.SmDim, styles.closeIcon]}
              resizeMode='contain'
            />
          </TouchableOpacity>
          <Button type='primary' onPress={props.onNext}>
            {'Pick a site →'}
          </Button>
        </Layout.EdgeGutter>
        <Layout.EdgeGutter style={[Constants.GridStyle.MRUnit, Constants.GridStyle.MH2Unit]}>
          <View style={Constants.GridStyle.MBUnit}>
            <View style={[Constants.GridStyle.MB2Unit, Constants.GridStyle.FLAIC]}>
              <View style={styles.pill}>
                <Image source={safetyIconSource} style={[Constants.ImageStyle.SmDim, Constants.GridStyle.MRUnit]} />
                <Text style={[Constants.TextStyle.T12B, Constants.TextStyle.CBlank]}>
                  {'Secured your link connection'}
                </Text>
              </View>
            </View>
            <Text style={[Constants.TextStyle.T24B, Constants.GridStyle.MB2Unit, Constants.TextStyle.ACenter]}>
              {'OneShop Link'}
            </Text>
          </View>
          <View style={Constants.GridStyle.MH2Unit}>
            <Text
              style={[
                Constants.TextStyle.T16R,
                Constants.GridStyle.MB4Unit,
                Constants.GridStyle.MH2Unit,
                Constants.TextStyle.ACenter,
                Constants.TextStyle.CDarkGray,
              ]}
            >
              {'Securely start a direct link with your accounts in seconds.'}{' '}
              <Text style={[Constants.TextStyle.T16B, Constants.TextStyle.CBlack]}>{'Your data belongs to you'}</Text>
              {'. We only use it with your permission.'}
            </Text>
            <Text
              style={[
                Constants.TextStyle.T16R,
                Constants.GridStyle.MB4Unit,
                Constants.GridStyle.MH2Unit,
                Constants.TextStyle.ACenter,
                Constants.TextStyle.CDarkGray,
              ]}
            >
              {'We use the same secure technology you’re probably already using for banking.'}
            </Text>
          </View>
        </Layout.EdgeGutter>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  pill: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Constants.NewColor.Black,
    paddingVertical: Constants.Grid.dp(6),
    paddingHorizontal: Constants.Grid.dp(12),
    borderRadius: Constants.Grid.dp(28),
  },
  closeIcon: {
    tintColor: Constants.NewColor.Black,
  },
});

export default LinkDisclaimerModal;
