import React from 'react';
import * as State from 'src/state';
import { Modal } from 'src/components/Modal';
import { ViewStyle, StyleProp } from 'react-native';
import LinkDisclaimerModal from '../LinkDisclaimer';
import SitePickerModal from '../SitePicker';
import LinkDirectModal from '../LinkDirect';
import MarketplaceSsoTutorialScreen from '../MarketplaceSsoTutorial';
import MarketplaceChromeExtension from '../MarketplaceChromeExtension';
import MarketplaceCredentialsCheckingScreen from '../MarketplaceCredentialsCheckingScreen';
import CheckingInAppMfaScreen from '../CheckingInAppMfaScreen';
import MarketplaceTokenMfaAnswerScreen from '../MarketplaceTokenMfaAnswerScreen';

interface PropsIface {
  onClose: () => void;
  visible: boolean;
  onSuccess?: () => void;
  onFailure?: () => void;
  style?: StyleProp<ViewStyle>;
}

enum LinkStage {
  Disclaimer = 0,
  SitePicker = 1,
  LinkDirect = 2,
  SsoTutorial = 3,
  WebLink = 4,
  LinkChecking = 5,
  TokenMfaAnswer = 6,
  CheckingInAppMfaAnswer = 7,
}

const LinkModal: React.FC<PropsIface> = (props) => {
  const [linkStage, setLinkStage] = React.useState<LinkStage>(LinkStage.Disclaimer);

  React.useEffect(() => {
    State.Observe.InstitutionLinks.SelectedInstitutionValue.set(null);
    setLinkStage(LinkStage.Disclaimer);
  }, [props.visible]);

  const toDisclaimerStage = React.useCallback(() => {
    setLinkStage(LinkStage.Disclaimer);
  }, []);

  const toSitePickerStage = React.useCallback(() => {
    setLinkStage(LinkStage.SitePicker);
  }, []);

  const toLinkDirectStage = React.useCallback(() => {
    setLinkStage(LinkStage.LinkDirect);
  }, []);

  const toSsoTutorialStage = React.useCallback(() => {
    setLinkStage(LinkStage.SsoTutorial);
  }, []);

  const toWebLinkStage = React.useCallback(() => {
    setLinkStage(LinkStage.WebLink);
  }, []);

  const toLinkChecking = React.useCallback(() => {
    setLinkStage(LinkStage.LinkChecking);
  }, []);

  const toTokenMfaAnswer = React.useCallback(() => {
    setLinkStage(LinkStage.TokenMfaAnswer);
  }, []);

  const toInAppMfaAnswer = React.useCallback(() => {
    setLinkStage(LinkStage.CheckingInAppMfaAnswer);
  }, []);

  const body =
    linkStage === LinkStage.Disclaimer ? (
      <LinkDisclaimerModal onNext={toSitePickerStage} onClose={props.onClose} />
    ) : linkStage === LinkStage.SitePicker ? (
      <SitePickerModal
        onBack={toDisclaimerStage}
        onLinkDirectPress={toLinkDirectStage}
        onWebLinkPress={toWebLinkStage}
      />
    ) : linkStage === LinkStage.LinkDirect ? (
      <LinkDirectModal onBack={toSitePickerStage} onInitiateLink={toLinkChecking} onSsoPress={toSsoTutorialStage} />
    ) : linkStage === LinkStage.SsoTutorial ? (
      <MarketplaceSsoTutorialScreen onBack={toLinkDirectStage} />
    ) : linkStage === LinkStage.WebLink ? (
      <MarketplaceChromeExtension onBack={toSitePickerStage} />
    ) : linkStage === LinkStage.LinkChecking ? (
      <MarketplaceCredentialsCheckingScreen
        onTokenMfaAnswer={toTokenMfaAnswer}
        onInAppMfaAnswer={toInAppMfaAnswer}
        onClose={props.onClose}
        onSuccess={props.onSuccess}
        onFailure={props.onFailure}
      />
    ) : linkStage === LinkStage.TokenMfaAnswer ? (
      <MarketplaceTokenMfaAnswerScreen
        onLinkChecking={toLinkChecking}
        onClose={props.onClose}
        onFailure={props.onFailure}
      />
    ) : linkStage === LinkStage.CheckingInAppMfaAnswer ? (
      <CheckingInAppMfaScreen onClose={props.onClose} onFailure={props.onFailure} />
    ) : null;

  return (
    <Modal visible={props.visible} size='thin' onClose={props.onClose}>
      {body}
    </Modal>
  );
};

export default LinkModal;
